<template>
  <div class="main"
       v-if="serve.State">
                 <div class="textbox"
         style="padding:30px 0 0 20px">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path:'/service'}">金融服务</el-breadcrumb-item>
        <el-breadcrumb-item>服务详情</el-breadcrumb-item>

      </el-breadcrumb>
    </div>
    <!-- body start -->
    <div class="bodybox">

      <div class="textbox">
        <div class="leftbox">
          <el-tabs v-model="activeName"
                   @tab-click="handleClick">
            <el-tab-pane label="服务介绍"
                         name="first">
              <div class="syncbox">
                <div class="boxtitle">{{serve.Title}}</div>

              </div>

              <div class="htmlbox"
                   v-html="serve.Description"  ref="content"
          @click="imageEnlargement"></div>
            </el-tab-pane>
            <el-tab-pane label="企业介绍"
                         name="second">
              <div class="syncbox">
                <div class="boxtitle">{{compObj.Name}}</div>

              </div>
              <div class="htmlbox"
                   v-html="compObj.Description"  ref="content"
          @click="imageEnlargement"></div>
            </el-tab-pane>

          </el-tabs>

        </div>
        <div class="rightbox">
          <div class="card">
            <!-- <div class="card_title">企业标签</div> -->
            <div class="comimg"><img :src="compObj.Logo"
                   alt=""></div>
            <div class="comuser">
              <div class="title">联系人:</div>
              <div class="texts">{{serve.ContactPerson==null || serve.ContactPerson==""?compObj.ContactPerson:serve.ContactPerson}}</div>
              <div class="title">联系电话:</div>
              <div class="texts">{{serve.Tel==null || serve.Tel==""?compObj.Tel:serve.Tel}}</div>
            </div>
          </div>
          <div class="card">
            <div class="card_title">公司其他产品及服务</div>
            <div class="othernews"
                 v-for="(item,i) in list"
                 :key="i"
                 @click="ChangeObj(item)"><span>·</span>
                 <i>
                 {{item.Title}}
                 </i>
                 </div>

          </div>

        </div>
      </div>
    </div>
    <!-- body end -->

  </div>
</template>

<script>
// 公共样式

import '../assets/css/common.css'
export default {
  data() {
    return {
      activeName: 'first',
      activeIndex: '6',
      drawer: false,
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      navlist: [
        {
          names: '首页',
          path: '/',
          id: '1',
        },
        {
          names: '政策资讯',
          path: '/news',
          id: '2',
        },
        {
          names: '产业载体',
          path: '/carrier',
          id: '3',
        },
        {
          names: '企业互动',
          path: '/interaction',
          id: '4',
        },
        {
          names: '金融服务',
          path: '/service',
          id: '5',
        },
        {
          names: '企业服务',
          path: '/enterpriseServices',
          id: '6',
        },
        {
          names: '招聘服务',
          path: '/recruit',
          id: '7',
        },
      ],
      arr: ['zt1', 'zt2', 'zt3', 'zt4', 'zt5', 'zt6', 'zt7', 'zt8'],
      aa: ['不限', '周末双休', '五险一金', '包吃住', '带薪年假'],
      indexaa: 0,
      bb: ['不限', '综合', '电商', '文化创意', '互联网'],
      indexbb: 0,
      height: '400px',
      input2: '',
      list: [],
      compObj: {},
      serve: {},
       imgArr:[],
    }
  },

  watch: {
    windowWidth(val) {
      let that = this
      // console.log("实时屏幕宽度：", val);
      if (val > 990) {
        that.drawer = false
        that.height = '400px'
      } else if (val <= 900 && val > 400) {
        that.height = '200px'
      } else {
        that.height = '100px'
      }
    },
  },
  methods: {
      imageEnlargement(e) {
   
      if (e.target.nodeName == "IMG") {
        //判断点击富文本内容为img图片
        console.log(e.target.currentSrc);
        this.imgArr[0] = e.target.currentSrc;
        console.log(this.imgArr);

         this.$viewerApi({
          images: this.imgArr
        })
     
      } else {
        console.log("点击内容不为img");
      }
    },

        ChangeObj(item) {
      if (item.Type == 0) {
        this.$router.push({ path: "/serviceDetails", query: { SysID: item.SysID } });
      } else if (item.Type == 1) {
        this.activeName = "first";
        this.getnewsList(item.SysID);
      }else if(item.Type == 2){
        this.$router.push({ path: "/ZMBserviceDetails", query: { SysID: item.SysID } });
      }
    },
    getnewsList(id) {
      let that = this
      that
        .$post('/api/JRServe/ObjShow', {
          SysID: id,
        })
        .then(function (data) {
          console.log(data)
          if (data.Result) {
            that.list = data.Obj.ServeList
            let company = data.Obj.CompanyObj
            if (company.Logo) {
              company.Logo = that.urlOA + company.Logo.substr(1)
            }

            that.compObj = company
            let obj = data.Obj.Obj
            if (!obj.State) {
              that.$alert('该服务信息已下架，请查看其他信息', {
                confirmButtonText: '确定',
                callback: () => {
                  that.$router.go(-1)
                },
              })

              return
            }
            that.serve = obj
            window.scroll(0, 0);
          } else {
            that.$alert('该服务信息已下架，请查看其他信息', {
              confirmButtonText: '确定',
              callback: () => {
                that.$router.go(-1)
              },
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    choeseaa(index) {
      console.log(index)
      this.indexaa = index
    },
    choesebb(index) {
      console.log(index)
      this.indexbb = index
    },
    handleSelect(e) {
      let that = this
      console.log(e)
      that.$router.push(e)
      setTimeout(() => {
        that.drawer = false
      }, 500)
    },
    getlogin() {
      this.$router.push('/login')
    },
    register() {
      this.$router.push('/register')
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClick(tab, event) {
      console.log(tab, event)
    },
  },
  mounted() {
    let SysID = this.$route.query.SysID

    console.log(SysID)
    var that = this
    that.getnewsList(SysID)
    let windowWidth = that.windowWidth
    console.log(windowWidth)
    if (windowWidth < 900 && windowWidth > 400) {
      that.height = '200px'
    } else if (windowWidth < 400) {
      that.height = '150px'
    }
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight
        window.fullWidth = document.documentElement.clientWidth
        that.windowHeight = window.fullHeight // 高
        that.windowWidth = window.fullWidth // 宽
      })()
    }
  },
}
</script>


<style scoped  lang="scss">
// 内容区

.textbox {
  width: 1230px;
  margin-left: 50%;
  transform: translateX(-50%);
  //   background-color: #475669;
  // padding-top: 50px;
  overflow: hidden;
  .leftbox {
    float: left;
    width: 900px;

    .synctitle {
      width: 100%;
      height: 22px;
      line-height: 22px;
      padding-left: 10px;
      font-size: 22px;
      font-weight: 500;
      border-left: 4px solid #3273dc;
      box-sizing: border-box;
      margin-bottom: 20px;
    }
    .syncbox {
      position: relative;
      width: 100%;
      padding-left: 10px;
      box-sizing: border-box;
      .boxtitle {
        font-size: 28px;
        font-weight: 700;
      }
      .boxtags {
        width: 80%;
        padding: 10px 0;
        // box-sizing: border-box;
        overflow: hidden;
        span {
          float: left;
          padding: 2px 10px;
          background-color: #ed982c;
          color: #fff;
          font-size: 14px;
          border-radius: 2px;
          margin-right: 10px;
        }
      }
      .box_btn {
        position: absolute;
        bottom: 6px;
        right: 10px;
        padding: 10px 30px;
        background-color: #3fbcbb;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
      }
    }
    .htmlbox {
      width: 820px;
      margin-top: 20px;
      margin-left: 10px;
      padding: 20px;
      border-radius: 5px;
      background-color: #fff;
      // box-shadow: 0px 0px 20px 0 rgb(105 105 105 / 30%);
      margin-bottom: 50px;
      ::v-deep p {
        line-height: 30px;
        font-size: 15px;
        color: #555;
      }
      ::v-deep img {
        max-width: 100%;
      }
    }
    .combox {
      width: 860px;
      margin-top: 20px;
      margin-left: 10px;
      padding: 20px;
      border-radius: 5px;
      background-color: #fff;
      box-shadow: 0px 0px 20px 0 rgb(105 105 105 / 30%);
      margin-bottom: 100px;
      overflow: hidden;
      img {
        float: left;
        cursor: pointer;
        height: 40px;
      }
      span {
        float: left;
        height: 40px;
        cursor: pointer;
        line-height: 40px;
        font-size: 20px;
        margin-left: 20px;
        font-weight: 700;
      }
    }
  }
  .leftbox::v-deep .el-tabs__item {
    padding: 0 20px;
    height: 60px;
    box-sizing: border-box;
    line-height: 60px;
    display: inline-block;
    list-style: none;
    font-size: 18px;
    font-weight: 800;
    color: #303133;
    position: relative;
  }
  .leftbox::v-deep .el-tabs__nav-wrap::after {
    height: 1px;
  }
  .leftbox::v-deep .el-tabs__item.is-active {
    color: #409eff;
  }
  .rightbox {
    float: left;
    width: 300px;
    margin-left: 30px;
    margin-bottom: 30px;
    .card {
      padding: 10px 0;
      background-color: #f9f9f9;
      box-sizing: border-box;
      margin-top: 60px;
      overflow: hidden;
      .card_title {
        border-left: 4px solid #3273dc;
        height: 20px;
        line-height: 20px;
        padding-left: 20px;
        box-sizing: border-box;
        font-size: 18px;
      }
      .tags_box {
        width: 100%;
        padding: 0 15px;
        box-sizing: border-box;
        overflow: hidden;
        span {
          float: left;
          padding: 8px 20px;
          margin: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
      }
      .comimg {
        float: left;
        width: 100px;
        img {
          height: 80px;
          width: 80px;
          border-radius: 50%;
          margin-left: 20px;
          object-fit: scale-down;
          background-color: #fff;
        }
      }
      .comuser {
        float: left;
        width: 180px;
        margin-left: 20px;
        height: 140px;
        .title {
          height: 20px;
          line-height: 20px;
          font-size: 14px;
          color: #999;
        }
        .texts {
          height: 30px;
          line-height: 30px;
          margin-bottom: 20px;
          font-size: 14px;
          color: #555;
        }
      }
    }
  }
}
.othernews {
  margin-top: 10px;
  margin-bottom: 5px;
  padding-top: 10px;
  display: flex;
  // height: 30px;
  width: 260px;
  margin-left: 20px;
  //   line-height: 30px;
  border-top: 1px solid #eee;
  font-size: 14px;
  //   padding-left: 10px;
  box-sizing: border-box;
  cursor: pointer;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.othernews:hover {
  color: #3273dc;
}
.othernews span {

  height: 30px;
  padding-right: 10px;
  //   padding-top: 10px;
  color: #3273dc;
  font-size: 18px;
  font-weight: 800;
    line-height: 30px;
  box-sizing: border-box;
}
.othernews i {
 
  font-style: normal;
 line-height: 30px;
  height: 30px;
  padding-right: 10px;
  //   padding-top: 10px;
 
  // font-weight: 800;
  //   line-height: 30px;
  box-sizing: border-box;
}
.comlist {
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
  .imgbox {
    float: left;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .inforbox {
    float: left;
    margin-left: 10px;
    width: 200px;

    .comnames {
      height: 30px;
      line-height: 30px;
      font-size: 16px;
    }
    .money {
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      color: #999;
    }
  }
}

.aaa {
  display: none;
}
@media (max-width: 1201px) {
  .textbox {
    width: 940px;
  }

  h5 {
    padding: 10px;
    box-sizing: border-box;
  }
}
@media (max-width: 991px) {
  .aaa {
    display: block;
  }

  .bannerbox img {
    height: 100%;
  }
  .main .textbox {
    width: 100%;
    overflow: hidden;
    padding-top: 10px;
  }
}
@media (max-width: 500px) {
  .textbox .leftbox {
    float: left;
    width: 100%;
}
  .textbox .leftbox .htmlbox {
    width: 100%;
    margin-top: 20px;
    margin-left: 0;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: #fff;
    margin-bottom: 10px;
}
.textbox .leftbox .syncbox {
    position: relative;

    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}
.textbox .leftbox .syncbox .boxtitle{
    font-size: 18px;
    font-weight: 700;
}
}
</style>